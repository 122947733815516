import {
	VuexModule,
	Mutation,
	Action,
	getModule,
	Module
} from "vuex-module-decorators";

import store from "./index"
import StorageDb from '@/util/storage';
import { login, api_login } from '@/http/login';
import { Id } from '@/types/global';

interface mutation_login {
	username: string
	token: string
	id: Id
	role:number
	auth:[]
}


@Module({ name: 'user', dynamic: true, namespaced: true, stateFactory: true, store })
export default class User extends VuexModule {
	private TOKEN: string | null = StorageDb.getLocal('token')
	private USERNAME: string | null = StorageDb.getLocal('username')
	private ROLE: number | null = StorageDb.getLocal('role')
	private ID: Id | null = StorageDb.getLocal('id')
	private PERMISSIONS: [] = StorageDb.getLocal('permissions')

	@Mutation
	private LOGIN({ username, token, id, role,auth}: mutation_login) {
		this.USERNAME = username
		this.TOKEN = token
		this.ROLE = role
		this.ID = id
		this.PERMISSIONS = auth
	}

	@Mutation
	private LOGOUT() {
		this.USERNAME = ''
		this.ROLE = null
		this.TOKEN = null
		this.ID = ''
		this.PERMISSIONS = []
	}

	@Action({ rawError: true })
	public async login(info: login) {
		const { username, token, id, role,auth } = await api_login.login(info)
		StorageDb.setLocal('username', username)
		StorageDb.setLocal('role', Number(role))
		StorageDb.setLocal('token', token)
		StorageDb.setLocal('id', id)
		StorageDb.setLocal('permissions', auth)
		this.LOGIN({ username, role, token, id, auth })
	}

	@Action
	public logout() {
		StorageDb.removeLocal('username')
		StorageDb.removeLocal('role')
		StorageDb.removeLocal('token')
		StorageDb.removeLocal('id')
		StorageDb.removeLocal('permissions')
		this.LOGOUT()
	}


	// @Action
	// public async root_change(info: { id: Id }) {
	// 	const { username, token, id, role } = await api_login.root_change(info.id)
	// 	this.LOGIN({ username, role, token, id })
	// }

	public get token() {
		return this.TOKEN
	}

	public get user_id() {
		return this.ID
	}

	public get role() {
		return this.ROLE
	}

	public get auth() {
		return this.PERMISSIONS
	}

	public get user_info() {
		return {
			username: this.USERNAME
		}
	}
}


export const UserModule = getModule(User);
