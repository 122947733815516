
import { Http } from "@/http"
import { Id } from '@/types/global'


export interface login{
	account:string
	password:string
}

export interface login_info {
	id:Id
	username:string
	token:string
	role:number
	auth:[]
}

class HttpLogin extends Http{
	login(info:login):Promise<login_info>{
		return this.post(info)
	}
	root_change(id:Id):Promise<login_info>{
		return this.server.get(`/food_admin/store_choice/?store_id=${id}`)
	}
}

export const api_login =  new HttpLogin('/admin/login/')
